<template>
  <div class="dockerImage">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="hub" slot-scope="text, record">
        {{ record.hub }}/{{ record.name }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="edit(record)"> 修改 </a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="getDockerImageItemList(record, 1)">
          子列表
        </a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <a-modal
      :title="title"
      v-model="addShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <template v-if="title == '新增'">
          <a-form-item label="名称:">
            <a-input v-model="addData.name" placeholder="请输入名称" />
          </a-form-item>
          <a-form-item label="镜像仓库地址:">
            <a-input v-model="addData.hub" placeholder="请输入镜像仓库地址" />
          </a-form-item>
        </template>
        <a-form-item label="备注:">
          <a-input v-model="addData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="dockerImageName"
      v-model="itemListShow"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchButton">
        <a-button type="primary" @click="addItem" icon="plus">新增</a-button>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(itemColumns)"
        :columns="itemColumns"
        :dataSource="itemList"
        :pagination="itemPagination"
        size="small"
        :rowKey="(record) => record.label"
      >
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="detail(record)">详情</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="copyItem(record)">复制</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="editItem(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteItem(record)"
          >
            <template slot="title">确认是否删除{{ record.tag }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="itemListShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="updateItemTitle"
      v-model="updateItemShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="版本:" v-if="updateItemTitle !== '修改'">
          <a-input v-model="updateItemData.tag" placeholder="请输入版本" />
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="updateItemData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateItemShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="updateItemSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="detailTitle"
      v-if="detailShow"
      v-model="detailShow"
      :maskClosable="false"
      :footer="false"
      wrapClassName="detailClass"
      width="900px"
    >
      <a-layout class="detailLayout">
        <a-dropdown v-model="rightMenuShow" :trigger="['contextmenu']">
          <template slot="overlay">
            <a-menu>
              <a-menu-item @click="createDir" :disabled="disabled">
                新建文件夹
              </a-menu-item>
              <a-menu-item @click="uploadFile" :disabled="disabled">
                上传文件
              </a-menu-item>
              <a-menu-item
                @click="deleteFile"
                :disabled="path == rootDirectory + dockerImageItemId"
              >
                <a
                  href="javascript:;"
                  style="color: #ff4d4f"
                  :disabled="path == rootDirectory + dockerImageItemId"
                  >删除</a
                >
              </a-menu-item>
            </a-menu>
          </template>
          <a-layout-sider
            class="detailLayoutSlider"
            width="220"
            theme="light"
            style="flex: 0 0 auto; max-width: none; min-width: inherit"
            @contextmenu="rightClick"
          >
            <a-tree
              :treeData="dirList"
              show-line
              :loadData="loadData"
              @select="treeSelect"
              @rightClick="treeRightClick"
              :expandedKeys.sync="expandedKeys"
              :selectedKeys.sync="selectedKeys"
            >
            </a-tree>
          </a-layout-sider>
        </a-dropdown>
        <a-layout class="detailLayoutContent">
          <div id="tz" @mousedown="dragEagle"></div>
          <div class="fileContent">
            <codemirror
              v-if="isFile"
              v-model="fileContent"
              :options="options"
            ></codemirror>
          </div>
          <div class="detailLayoutContentFooter">
            <template v-if="fileContent !== watchFileContent">
              <a-button type="info" @click="fileContent = watchFileContent">
                取消
              </a-button>
              <a-popconfirm
                placement="top"
                okText="确认"
                cancelText="取消"
                @confirm="editFileContent"
              >
                <template slot="title">确认是否修改?</template>
                <a-button type="primary">保存修改</a-button>
              </a-popconfirm>
            </template>
            <a-popconfirm
              placement="top"
              okText="确认"
              cancelText="取消"
              @confirm="build"
              v-else
            >
              <template slot="title">确认是否构建镜像?</template>
              <a-button type="primary">构建镜像</a-button>
            </a-popconfirm>
          </div>
        </a-layout>
      </a-layout>
    </a-modal>

    <a-modal
      title="创建文件夹"
      v-if="createDirShow"
      v-model="createDirShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件夹名称:">
          <a-input v-model="dirName" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="createDirShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="createDirSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="上传文件"
      v-if="uploadFileShow"
      v-model="uploadFileShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="文件:">
          <a-upload-dragger
            ref="upload"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
            :fileList="fileList"
            :showUploadList="false"
            directory
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
          </a-upload-dragger>
          <h4 v-if="fileList.length > 0">已上传{{ fileList.length }}个文件</h4>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadFileShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :disabled="fileList.length == 0"
            @click="uploadFileSubmit()"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      v-if="logVisible"
      v-model="logVisible"
      :maskClosable="false"
      :afterClose="logClose"
      width="1400px"
      :footer="false"
    >
      <template slot="title">
        正在构建{{ this.detailTitle }}
        <div style="display: inline-block">
          <a-tooltip placement="bottom" v-if="logStatus == 'RUNNING'">
            <div slot="title">构建中</div>
            <a-icon type="loading" style="margin-left: 10px" />
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="logStatus == 'SUCCESS'">
            <div slot="title">构建成功</div>
            <div class="rage" style="background-color: #52c41a"></div>
          </a-tooltip>
          <a-tooltip placement="bottom" v-if="logStatus == 'ERROR'">
            <div slot="title">构建失败</div>
            <div class="rage" style="background-color: #f5222d"></div>
          </a-tooltip>
        </div>
      </template>
      <div
        id="terminal"
        style="height: 700px; padding: 10px; background: #008080"
      ></div>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/dockerImage.js";
import "../../node_modules/xterm/css/xterm.css";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

export default {
  name: "dockerImage",
  data() {
    return {
      name: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "hub仓库",
          dataIndex: "hub",
          ellipsis: true,
          scopedSlots: { customRender: "hub" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      dockerImageId: "",
      dir: "",
      title: "",
      addData: {},
      addShow: false,
      itemColumns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
        },
        {
          title: "版本",
          dataIndex: "tag",
          ellipsis: true,
        },
        {
          title: "备注",
          dataIndex: "remark",
          ellipsis: true,
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      itemList: [],
      itemPagination: {},
      itemPageNo: 1,
      itemListShow: false,
      updateItemTitle: "",
      updateItemShow: false,
      updateItemData: {},
      dockerImageItemId: "",
      rootDirectory: "/opt/delivery/beta-delivery-web/docker-image/",
      dirList: [],
      expandedKeys: [],
      selectedKeys: [],
      dockerImageName: "",
      detailTitle: "",
      detailShow: false,
      fileContent: "",
      watchFileContent: "",
      options: {
        mode: "text/javascript",
        theme: "default",
        readOnly: false,
        lineNumbers: true,
        line: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
      },
      isFile: false,
      rightMenuShow: false,
      dirName: "",
      createDirShow: false,
      path: "",
      fileList: [],
      uploadFileShow: false,
      logVisible: false,
      getLogTimer: null,
      disabled: false,
      logStatus: "RUNNING",
      syncLoading: null,
    };
  },
  created() {
    this.query();
    document.body.addEventListener("click", () => {
      this.rightMenuShow = false;
    });
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .dockerImagePage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    add() {
      this.title = "新增";
      this.addData = {
        name: "",
        hub: "hubcore.huiyu.org.cn",
        remark: "",
      };
      this.addShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.dockerImageId = val.id;
      this.addData = {
        name: val.name,
        hub: val.hub,
        remark: val.remark,
      };
      this.addShow = true;
    },
    addSubmit() {
      let data = {
        ...this.addData,
      };
      if (this.title == "新增") {
        api.addDockerImage(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.addShow = false;
            this.query(1);
          }
        });
      } else {
        data.id = this.dockerImageId;
        api.editDockerImage(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addShow = false;
            this.query();
          }
        });
      }
    },
    getDockerImageItemList(record, index) {
      if (record) {
        this.dockerImageId = record.id;
        this.dockerImageName = record.hub + "/" + record.name;
      }
      if (index) {
        this.itemPageNo = index;
      }
      let data = {
        pageNo: this.itemPageNo,
        pageSize: 10,
        imageId: this.dockerImageId,
      };
      api.dockerImageItemPage(data).then((res) => {
        if (res.result == 200) {
          this.itemList = res.data.records;
          this.itemPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.getDockerImageItemList(null, current),
          };
          if (record) {
            this.itemListShow = true;
          }
        }
      });
    },
    addItem() {
      this.updateItemTitle = "新增";
      this.updateItemData = {
        tag: "",
        remark: "",
      };
      this.updateItemShow = true;
    },
    editItem(val) {
      this.updateItemTitle = "修改";
      this.dockerImageItemId = val.id;
      this.updateItemData = {
        tag: val.tag,
        remark: val.remark,
      };
      this.updateItemShow = true;
    },
    copyItem(val) {
      this.updateItemTitle = "复制";
      this.dockerImageItemId = val.id;
      this.updateItemData = {
        tag: val.tag,
        remark: val.remark,
      };
      this.updateItemShow = true;
    },
    updateItemSubmit() {
      let data = {
        imageId: this.dockerImageId,
        ...this.updateItemData,
      };
      if (this.updateItemTitle == "新增") {
        api.addDockerImageItem(data).then((res) => {
          if (res.result === 200) {
            this.$message.success("添加成功");
            this.getDockerImageItemList(null, 1);
            this.updateItemShow = false;
          }
        });
      } else {
        data.id = this.dockerImageItemId;
        if (this.updateItemTitle == "修改") {
          api.editDockerImageItem(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.getDockerImageItemList();
              this.updateItemShow = false;
            }
          });
        } else {
          this.syncLoading = this.$syncLoading({ message: "复制中..." });
          api
            .copy(data)
            .then((res) => {
              if (res.result === 200) {
                this.syncLoading.close();
                this.$message.success("复制成功");
                this.getDockerImageItemList(null, 1);
                this.updateItemShow = false;
              }
            })
            .catch((err) => {
              this.syncLoading.close();
            });
        }
      }
    },
    deleteItem(val) {
      let data = {
        id: val.id,
      };
      api.deleteDockerImageItem(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.getDockerImageItemList();
        }
      });
    },
    detail(val) {
      this.dockerImageItemId = val.id;
      this.dir = this.rootDirectory + this.dockerImageItemId;
      this.fileContent = "";
      this.watchFileContent = "";
      this.isFile = false;
      this.detailTitle = this.dockerImageName + ":" + val.tag;
      this.expandedKeys.splice(0);
      this.$set(this.options, "mode", "text/javascript");
      this.loadData();
    },
    async loadData(treeNode) {
      let data = {
        dir: "",
      };
      if (treeNode) {
        if (treeNode.dataRef.children) {
          return;
        }
        data.dir = treeNode.dataRef.key;
      } else {
        data.dir = this.dir;
      }

      api.queryDir(data).then((res) => {
        if (res.result === 200) {
          let arr = res.data.map((item) => ({
            title: item.name.split("/")[item.name.split("/").length - 1],
            key: item.name,
            isDir: item.directory,
            isLeaf: !item.directory,
          }));
          if (treeNode) {
            // 展开文件夹动态加载
            treeNode.dataRef.children = arr;
            this.dirList = [...this.dirList];
          } else if (this.dir == this.rootDirectory + this.dockerImageItemId) {
            // 查询根目录
            this.dirList = arr;
            this.detailShow = true;
            let hasDockerfile = this.dirList.some((item) => {
              return item.title == "Dockerfile";
            });
            if (hasDockerfile) {
              setTimeout(() => {
                this.isFile = true;
                this.path =
                  this.rootDirectory + this.dockerImageItemId + "/Dockerfile";
                this.selectedKeys = [this.path];
                this.getFileContent();
              }, 200);
            }
          } else {
            // 手动操作后重新查询
            const parentNode = this.findNode(this.dirList, data.dir);
            this.$set(parentNode, "children", arr);
            this.dirList = [...this.dirList];
            if (!this.expandedKeys.includes(data.dir)) {
              this.expandedKeys.push(data.dir);
            }
            this.expandedKeys = [...this.expandedKeys];
          }
        }
      });
    },
    // 递归查询节点
    findNode(treeData, key) {
      for (const node of treeData) {
        if (node.key === key) {
          return node;
        }
        if (node.children) {
          const found = this.findNode(node.children, key);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },
    treeSelect(keys, event) {
      let treeNode = event.node.dataRef;
      this.isFile = !treeNode.isDir;
      let hasTarGzOrJar =
        treeNode.title.endsWith(".tar.gz") || treeNode.title.endsWith(".jar");
      if (treeNode.title.endsWith(".yaml")) {
        this.$set(this.options, "mode", "text/x-yaml");
      } else {
        this.$set(this.options, "mode", "text/javascript");
      }
      if (hasTarGzOrJar) {
        this.isFile = false;
        this.fileContent = "";
        this.watchFileContent = "";
      }
      if (this.isFile && !hasTarGzOrJar) {
        this.path = treeNode.key;
        this.getFileContent();
      }
    },
    getFileContent() {
      let data = {
        path: this.path,
      };

      api.viewContent(data).then((res) => {
        if (res.result === 200) {
          this.fileContent = res.data;
          this.watchFileContent = res.data;
        }
      });
    },
    dragEagle(e) {
      var targetDiv = document.getElementsByClassName("detailLayoutSlider")[0];

      //得到点击时该容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var startX = e.clientX;

      document.onmousemove = (e) => {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        //往右方拖动：
        if (e.clientX > startX) {
          targetDiv.style.width = targetDivWidth + distX + "px";
        }
        //往左方拖动：
        if (e.clientX < startX) {
          targetDiv.style.width = targetDivWidth - distX + "px";
        }
        //设置最大最小范围：不能无限制缩放，影响体验
        if (parseInt(targetDiv.style.width) <= 60) {
          targetDiv.style.width = 60 + "px";
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
    editFileContent() {
      let data = {
        path: this.path,
        content: this.fileContent,
      };
      api.editContent(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.watchFileContent = this.fileContent;
        }
      });
    },
    rightClick(event) {
      // 只在空白区域的右键点击时触发
      if (!event.target.closest(".ant-tree")) {
        this.disabled = false;
        this.path = this.rootDirectory + this.dockerImageItemId;
      }
    },
    treeRightClick({ node }) {
      this.path = node.dataRef.key;
      this.disabled = !node.dataRef.isDir;
    },
    createDir() {
      this.dirName = "";
      this.createDirShow = true;
    },
    createDirSubmit() {
      let data = {
        path: this.path + "/" + this.dirName,
      };
      api.mkdir(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("创建成功");
          this.createDirShow = false;
          this.dir = this.path;
          this.loadData();
        }
      });
    },
    uploadFile() {
      this.fileList.splice(0);
      this.uploadFileShow = true;
    },
    beforeUpload(file, fileList) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    uploadFileSubmit() {
      this.$axios
        .all(
          this.fileList.map((file) => {
            let data = {
              file,
              path: this.path + "/" + file.webkitRelativePath,
            };
            return api.uploadFile(data);
          })
        )
        .then((resArr) => {
          this.fileList.splice(0);
          this.$message.success("上传成功");
          this.uploadFileShow = false;
          this.dir = this.path;
          this.loadData();
        });
    },
    deleteFile() {
      this.$confirm({
        title: "确认",
        content: (h) => (
          <div style="color:red;">
            删除会立即删除源文件且不可撤销, 是否继续?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let data = {
            path: this.path,
          };

          api.rmFile(data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.createDirShow = false;
              this.dir = this.path.slice(0, this.path.lastIndexOf("/"));
              this.loadData();
            }
          });
        },
        onCancel: () => {},
      });
    },
    build() {
      let data = {
        id: this.dockerImageItemId,
      };
      api.build(data).then((res) => {
        if (res.result === 200) {
          this.logVisible = true;
          this.$nextTick(() => {
            this.term = new Terminal({
              theme: {
                background: "#008080", //背景色
                black: "#003440",
                blue: "#268ad2",
                brightBlack: "#00779a",
                brightBlue: "#109fd2",
                BrightCyan: "#00bdae",
                cyan: "#2aa197",
                brightGreen: "#5bee96",
                green: "#7cc67f",
                brightMagenta: "#e9679f",
                megenta: "#d33582",
                brightRed: "#f9314b",
                red: "#dc312e",
                brightWhite: "#fdf6e3",
                white: "#eee8d5",
                brightYellow: "#c08f34",
                yellow: "#b58900",
                foreground: "#f0f0f0", //字体
                selection: "#2c9aff66",
                cursor: "#f86100",
                cursorAccent: "#003440",
              },
            });
            const fitAddon = new FitAddon();
            this.term.loadAddon(fitAddon);
            this.term.open(document.getElementById("terminal"));
            fitAddon.fit();
            this.term.write("\x1b[2K\r");
            this.term.clear();
            this.logStatus = "RUNNING";
            this.getLogs();
          });
        }
      });
    },
    logClose() {
      this.term.dispose();
      if (this.getLogTimer) {
        clearInterval(this.getLogTimer);
        this.getLogTimer = null;
      }
    },
    autoRefresh() {
      if (this.getLogTimer) {
        clearInterval(this.getLogTimer);
        this.getLogTimer = null;
      }
      this.getLogTimer = setInterval(() => {
        this.getLogs();
      }, 1000);
    },
    getLogs() {
      let data = {
        id: this.dockerImageItemId,
      };
      api.getLogs(data).then((res) => {
        if (res.result === 200) {
          if (res.data.logs.length > 0) {
            res.data.logs.forEach((item) => {
              this.term.write(item + "\r\n");
            });
          }
          if (res.data.success == null) {
            if (!this.getLogTimer) {
              this.autoRefresh();
            }
          } else {
            if (res.data.success) {
              this.logStatus = "SUCCESS";
            } else {
              this.logStatus = "ERROR";
            }
            if (this.getLogTimer) {
              clearInterval(this.getLogTimer);
              this.getLogTimer = null;
            }
          }
        }
      });
    },
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteDockerImage(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
