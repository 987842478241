<template functional>
  <a-sub-menu
    :key="props.item.path"
    :class="{ 'ant-menu-item-selected': props.item.selectShow }"
    v-on="listeners"
  >
    <span
      slot="title"
      style="display: inline-block; width: 100%"
      @click.stop="listeners.nodeDetail(props.item, true)"
    >
      <span class="name">{{ props.item.name }}</span>
      <span
        style="float: right"
        @click.stop="listeners.deleteNode(props.item.path)"
      >
        <a-tooltip>
          <template slot="title">删除节点</template>
          <a-icon type="delete" />
        </a-tooltip>
      </span>
      <span
        style="float: right"
        @click.stop="listeners.createNode(props.item.path)"
      >
        <a-tooltip>
          <template slot="title">添加节点</template>
          <a-icon type="plus" />
        </a-tooltip>
      </span>
    </span>
    <template v-for="menu in props.item.list">
      <sub-menu
        v-if="menu.list.length > 0"
        :item="menu"
        @nodeDetail="listeners.nodeDetail"
        @deleteNode="listeners.deleteNode"
        @createNode="listeners.createNode"
      ></sub-menu>
      <template v-else>
        <a-menu-item :key="menu.path">
          <span
            style="display: inline-block; width: 100%"
            @click.stop="listeners.nodeDetail(menu, false)"
          >
            <span class="name">{{ menu.name }}</span>
            <span
              style="float: right"
              @click.stop="listeners.deleteNode(menu.path)"
            >
              <a-tooltip>
                <template slot="title">删除节点</template>
                <a-icon type="delete" />
              </a-tooltip>
            </span>
            <span
              style="float: right"
              @click.stop="listeners.createNode(menu.path)"
            >
              <a-tooltip>
                <template slot="title">添加节点</template>
                <a-icon type="plus" />
              </a-tooltip>
            </span>
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
export default {
  name: "subMenu",
  props: ["item"],
};
</script>

<style></style>
