import http from "./axios";

export const namespaceList = (data) => {
  return http.get("/k8s/namespaceList", data);
};

export const nodeList = (data) => {
  return http.get("/k8s/nodeList", data);
};

export const podList = (data) => {
  return http.get("/k8s/podList", data);
};

export const getPodMetric = (data) => {
  return http.get("/k8s/getPodMetric", data);
};

export const deploymentList = (data) => {
  return http.get("/k8s/deploymentList", data);
};

export const statefulSetList = (data) => {
  return http.get("/k8s/statefulSetList", data);
};

export const configMapList = (data) => {
  return http.get("/k8s/configMapList", data);
};

export const pvcNameList = (data) => {
  return http.get("/k8s/pvcNameList", data);
};

export const ingressList = (data) => {
  return http.get("/k8s/ingressList", data);
};

export const ingressClassList = (data) => {
  return http.get("/k8s/ingressClassList", data);
};

export const serviceList = (data) => {
  return http.get("/k8s/serviceList", data);
};

export const schedulingTemplatePage = (data) => {
  return http.get("/k8s/schedulingTemplatePage", data);
};

export const kubeSchedulingUpdateMethodEnum = (data) => {
  return http.get("/k8s/kubeSchedulingUpdateMethodEnum", data);
};

export const pvcList = (data) => {
  return http.get("/k8s/pvcList", data);
};

export const secretList = (data) => {
  return http.get("/k8s/secretList", data);
};

export const eventPage = (data) => {
  return http.get("/k8s/eventPage", data);
};

export const eventHistoryPage = (data) => {
  return http.get("/k8s/eventHistoryPage", data);
};

export const deploymentVersionPage = (data) => {
  return http.get("/k8s/deploymentVersionPage", data);
};

export const nodeAffinityPage = (data) => {
  return http.get("/k8s/nodeAffinityPage", data);
};

export const nodeAffinityList = (data) => {
  return http.get("/k8s/nodeAffinityList", data);
};

export const probeTemplatePage = (data) => {
  return http.get("/k8s/probe/templatePage", data);
};

export const templateDetail = (data) => {
  return http.get("/k8s/probe/templateDetail", data);
};

export const listPriorityClass = (data) => {
  return http.get("/k8s/listPriorityClass", data);
};

export const pvList = (data) => {
  return http.get("/k8s/pvList", data);
};

export const k8sIngressClassPage = (data) => {
  return http.get("/k8sIngressClass/k8sIngressClassPage", data);
};

export const mavenVersionPage = (data) => {
  return http.get("/mavenVersion/mavenVersionPage", data);
};

export const addMavenVersion = (data) => {
  return http.json_post("/mavenVersion/addMavenVersion", data);
};

export const editMavenVersion = (data) => {
  return http.json_post("/mavenVersion/editMavenVersion", data);
};

export const deleteMavenVersion = (data) => {
  return http.json_post("/mavenVersion/deleteMavenVersion", data);
};

export const editContainerGroupNum = (data) => {
  return http.json_post("/k8s/editContainerGroupNum", data);
};

export const redeployment = (data) => {
  return http.post("/k8s/redeployment", data);
};

export const deploymentRollBack = (data) => {
  return http.post("/k8s/deploymentRollBack", data);
};

export const addConfigMap = (data) => {
  return http.json_post("/k8s/addConfigMap", data);
};

export const editConfigMap = (data) => {
  return http.json_post("/k8s/editConfigMap", data);
};

export const deletePod = (data) => {
  return http.json_post("/k8s/deletePod", data);
};

export const deleteDeployment = (data) => {
  return http.json_post("/k8s/deleteDeployment", data);
};

export const deleteStatefulSet = (data) => {
  return http.json_post("/k8s/deleteStatefulSet", data);
};

export const deleteConfigMap = (data) => {
  return http.json_post("/k8s/deleteConfigMap", data);
};

export const updateIngressRule = (data) => {
  return http.json_post("/k8s/updateIngressRule", data);
};

export const deleteIngress = (data) => {
  return http.json_post("/k8s/deleteIngress", data);
};

export const createService = (data) => {
  return http.json_post("/k8s/createService", data);
};

export const deleteService = (data) => {
  return http.json_post("/k8s/deleteService", data);
};

export const addSchedulingTemplate = (data) => {
  return http.json_post("/k8s/addSchedulingTemplate", data);
};

export const editSchedulingTemplate = (data) => {
  return http.json_post("/k8s/editSchedulingTemplate", data);
};

export const deleteSchedulingTemplate = (data) => {
  return http.json_post("/k8s/deleteSchedulingTemplate", data);
};

export const addNodeAffinityTemplate = (data) => {
  return http.json_post("/k8s/addNodeAffinityTemplate", data);
};

export const editNodeAffinityTemplate = (data) => {
  return http.json_post("/k8s/editNodeAffinityTemplate", data);
};

export const delNodeAffinity = (data) => {
  return http.json_post("/k8s/delNodeAffinity", data);
};

export const editNodeAffinity = (data) => {
  return http.json_post("/k8s/editNodeAffinity", data);
};

export const deletePvc = (data) => {
  return http.json_post("/k8s/deletePvc", data);
};

export const addTemplate = (data) => {
  return http.json_post("/k8s/probe/addTemplate", data);
};

export const editTemplate = (data) => {
  return http.json_post("/k8s/probe/editTemplate", data);
};

export const delTemplate = (data) => {
  return http.json_post("/k8s/probe/delTemplate", data);
};

export const addLabelToNode = (data) => {
  return http.json_post("/k8s/addLabelToNode", data);
};

export const removeLabelFromNode = (data) => {
  return http.json_post("/k8s/removeLabelFromNode", data);
};

export const markUnschedulable = (data) => {
  return http.json_post("/k8s/markUnschedulable", data);
};

export const createPriorityClass = (data) => {
  return http.json_post("/k8s/createPriorityClass", data);
};

export const updatePriorityClass = (data) => {
  return http.json_post("/k8s/updatePriorityClass", data);
};

export const deletePriorityClass = (data) => {
  return http.json_post("/k8s/deletePriorityClass", data);
};

export const syncIngressClass = (data) => {
  return http.json_post("/k8sIngressClass/syncIngressClass", data);
};

export const editK8sIngressClass = (data) => {
  return http.json_post("/k8sIngressClass/editK8sIngressClass", data);
};

export const getDns = (data) => {
  return http.get("/k8sIngressClass/getDns", data);
};
